@import "./variables.scss";
@import "dialog";
@import "font";

//recharts override
.recharts-rectangle {
  &:hover {
    cursor: pointer !important;
  }
}
//end recharts

body {
  overflow: auto !important;
  background-color: rgb(240, 242, 245) !important;
  width: 100% !important;

  &.openDialog {
    #root {
      filter: blur(2px);
    }
  }
}

@media (min-width: 250px) and (max-width: 640px) {
  .container {
    max-width: 100%;
  }
}

.button-success-default {
  // background-color: $success;
  border: solid 1.5px $success;
  color: $success;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  border-color: $success;

  &:hover {
    color: $white;
    background-color: $success-hover;
    border-color: $success-hover;
  }

  &:focus {
    color: $white;
    background-color: $success-hover;
    border-color: $success-hover;
  }
}

.button-success {
  background-color: $success;
  border: $success;
  color: $white;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);

  &:hover {
    color: $white;
    background-color: $success-hover;
    border-color: $success-hover;
  }
  &:focus {
    color: $white;
    background-color: $success-hover;
    border-color: $success-hover;
  }
}

.button-info {
  background-color: $info;
  border: $info;
  color: $white;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);

  &:hover {
    color: $white;
    background-color: $info-hover;
    border-color: $info-hover;
  }
  &:focus {
    color: $white;
    background-color: $info-hover;
    border-color: $info-hover;
  }
}

.button-warning {
  background-color: $warning;
  border: $warning;
  color: $white;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);

  &:hover {
    color: $white;
    background-color: $warning-hover;
    border-color: $warning-hover;
  }
  &:focus {
    color: $white;
    background-color: $warning-hover;
    border-color: $warning-hover;
  }
}

.button-warning-dashed {
  background-color: $white;
  border-style: dashed;
  border-color: $warning-hoverDark;
  color: $warning-hoverDark;
  &:hover {
    background-color: $white;
    color: $warning;
    border-color: $warning;
  }
  &:focus {
    background-color: $white;
    color: $warning;
    border-color: $warning;
  }
}

.button-success-dashed {
  background-color: $white;
  border-style: dashed;
  border-color: $success-hoverDark;
  color: $success-hoverDark;
  &:hover {
    background-color: $white;
    color: $success;
    border-color: $success;
  }
  &:focus {
    background-color: $white;
    color: $success;
    border-color: $success;
  }
}

.button-info-dashed {
  background-color: $white;
  border-style: dashed;
  border-color: $info-hoverDark;
  color: $info-hoverDark;
  &:hover {
    background-color: $white;
    color: $info;
    border-color: $info;
  }
  &:focus {
    background-color: $white;
    color: $info;
    border-color: $info;
  }
}

.tagClose14px {
  .ant-tag {
    .anticon-close {
      font-size: 12px;
      //vertical-align: baseline !important;
      //margin-left: 6px !important;
      &:hover {
        color: $danger-hover;
      }
    }
  }
}

svg {
  vertical-align: baseline;
}

::-webkit-scrollbar {
  display: none;
}
