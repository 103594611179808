.text-blue {
  color: #003970 !important;
}

.text-green {
  color: #80eec2 !important;
}

.subheadline-text-color {
  color: #1c3b64;
}

.btn-primary {
  background-color: #80eec2 !important;
  border: #6eecba !important;
  color: #003970 !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  font-weight: 700;
}

.bg-primary {
  background-color: #003970 !important;
}

.bg-secondary {
  background-color: #80eec2 !important;
}

.bg-primary,
.ant-btn-primary {
  background-color: #003970 !important;
}

.bg-secondary,
.ant-table-cell.bg-primary {
  background-color: #80eec2 !important;
}

.txt-color-secondary {
  color: #003970;
}

.ant-btn {
  font-weight: 700 !important;
}

.ant-btn-link {
  font-weight: 400 !important;
}

.form-create {
  min-width: 800px;
}

@media (max-width: 800px) {
  .form-create {
    min-width: unset;
}}
