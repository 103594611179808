//COLORS
$danger: #db5151;
$danger-lighter: #ed938d;
$danger-hover: #e06160;
$danger-hoverDark: #c84747;
$danger-dark: #8f2929;

$warning: #f9bf00;
$warning-lighter: #fcdb2a;
$warning-hover: #fac60a;
$warning-hoverDark: #e0a700;
$warning-dark: #976000;

$primary: #29aae1;
$primary-lighter: #67d5f0;
$primary-hover: #38b5e5;
$primary-hoverDark: #2497cc;
$primary-dark: #155d8c;
$primary-custom: #2392c1;

$info: #b6e6fb;
$info-lighter: #c7f3fd;
$info-hover: #bae9fc;
$info-hoverDark: #9fcadf;
$info-dark: #5b788b;

$success: #8bc53f;
$success-lighter: #a8db5b;
$success-hover: #95cc48;
$success-hoverDark: #7aaf37;
$success-dark: #466d20;
$success-custom: #7bab3c;

$white: #ffffff;
$white-lighter: #ffffff;
$white-hover: #ffffff;
$white-hoverDark: #dfdfdf;
$white-dark: #808080;

$gray: #b5bec6;
$gray-lighter: #c4c4c4;
$gray-hover: #bdc6cd;
$gray-hoverDark: #262626;
$gray-dark: #303343;

$black: #000000;
$black-lighter: #7b7b7b;
$black-hover: #464646;
$black-hoverDark: #9ea6af;
$black-dark: #161616;
