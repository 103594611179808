@import "../styles/variables";

.newMessage {
  svg {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    font-size: 10rem;
    color: $primary;
    opacity: 0.35;
    transform: translateX(25%);
  }
}

.callAnimationEasyMd {
  width: 110px;
  height: 110px;
  position: relative;
  margin: 0 auto;
  border-radius: 100%;
  animation: play 1.5s ease infinite;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

.imgAvatar {
  width: 110px;
  height: 110px;
  border-radius: 100%;
  border: solid 5px white;
  position: absolute;
  left: 0px;
  top: 0px;
}

@keyframes play {
  0% {
    transform: scale(1);
  }

  15% {
    box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.4);
  }

  25% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.4), 0 0 0 20px rgba(255, 255, 255, 0.2);
  }

  25% {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0.4), 0 0 0 30px rgba(255, 255, 255, 0.2);
  }
}
