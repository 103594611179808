/* for desktop */
.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  &:hover {
    color: #fff;
    text-decoration: none;
  }
}

.whatsapp-icon {
  margin-top: 16px;
}

.slideUp {
  animation: slideUp ease-out 1s;
}
@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.shake:hover {
  animation: shake 0.82s cubic-bezier(0.036, 0.007, 0.019, 0.097) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1px;
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-0.1px, 0, 0);
  }
  20%,
  90% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-0.4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
    margin-top: 10px;
  }

  .whatsapp_float {
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 10px;
    font-size: 22px;
  }
}
